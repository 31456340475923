// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Banner_banner__g0ubw {
  background: black;
  padding: 1rem;
  text-align: center;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Premium/Banner.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,aAAA;EACA,kBAAA;EACA,YAAA;AACF","sourcesContent":[".banner {\r\n  background: black;\r\n  padding: 1rem;\r\n  text-align: center;\r\n  color: white;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner": `Banner_banner__g0ubw`
};
export default ___CSS_LOADER_EXPORT___;
