import { Link } from "react-router-dom";
import styles from "./Navbar.module.scss";
import { useContext } from "react";
import { UserContext } from "../../UserContext";
import { AlertInfo } from "../../interfaces";

function Navbar(props: {
  onTokenChange: () => void;
  handleAlert: (alertObj: AlertInfo) => void;
}) {
  const user = useContext(UserContext);

  return (
    <nav className={styles.navbarmain}>
      <Link className={styles.linklogo} to={"/"}>
        <img className={styles.logo} src="/logo.webp" alt="Logo site Real-life" />
      </Link>

      <div className={styles.link}>
        {user ? (
          <div className={styles.linknav_box}>
            <Link
              className={`${styles.linknav} rounded bg-blue-800`}
              to={""}
              onClick={() => {
                props.onTokenChange();
                props.handleAlert({
                  message: "Vous êtes déconnecté(e)",
                  variant: "warning",
                });
              }}
            >
              Déconnexion
            </Link>
            <Link className={`${styles.linknav} rounded bg-blue-800 px-6`} to={"/profile"}>
              Mon profil
            </Link>
          </div>
        ) : (
          <>
            <Link className={`${styles.linknav} bg-blue-800`} to={"/signup"}>
              <p>Inscription</p>
            </Link>
            <Link className={`${styles.linknav} bg-blue-800`} to={"/login"}>
              <p>Connexion</p>
            </Link>
          </>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
