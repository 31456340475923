import React from 'react'

interface TruncateProps {
  paragraphs: string;
  sliceNumber?: number; // This is optional because we have a default value
}

function Truncate({ paragraphs, sliceNumber = 30 }: TruncateProps) {
  // Ensure paragraphs is a string
  const text = typeof paragraphs === 'string' ? paragraphs : '';
  
  // Split the text into words
  const words = text.split(/\s+/);

  // Truncate the text to the specified number of words
  let truncatedText = words.slice(0, sliceNumber).join(' ');

  // Add ellipsis if the original text had more words than the limit
  if (words.length > sliceNumber) {
    truncatedText += '...';
  }

  // Return the truncated text inside a paragraph element
  return (
    <p className='first-letter:uppercase'>
      {truncatedText}
    </p>
  );
}

export default Truncate;


