// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navbar_navbarmain__kHLkK {
  position: sticky;
  top: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  z-index: 1000;
  padding: 2px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
}
.Navbar_navbarmain__kHLkK .Navbar_logo__YJM-Q {
  width: 100%;
  max-height: 4.4rem;
  object-fit: cover;
  object-position: center;
}
.Navbar_navbarmain__kHLkK .Navbar_link__Zoaxm {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.Navbar_navbarmain__kHLkK .Navbar_linknav_box__mSshM {
  display: flex;
  flex-direction: column;
}
.Navbar_navbarmain__kHLkK .Navbar_linknav__NPr4n {
  text-decoration: none;
  align-self: center;
  font-size: 1rem;
  padding: 5px 10px;
  margin: 3px auto;
  max-height: 40px;
  color: white;
  letter-spacing: 0.7px;
  min-width: 9rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar/Navbar.module.scss"],"names":[],"mappings":"AAAA;EAEE,gBAAA;EACA,MAAA;EACA,0CAAA;EACA,uBAAA;EACA,aAAA;EACA,iBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;AACF;AAAE;EACE,WAAA;EACA,kBAAA;EACA,iBAAA;EACA,uBAAA;AAEJ;AAAE;EACE,aAAA;EACA,sBAAA;EACA,6BAAA;AAEJ;AACE;EACE,aAAA;EACA,sBAAA;AACJ;AAEE;EACE,qBAAA;EACA,kBAAA;EACA,eAAA;EAEA,iBAAA;EACA,gBAAA;EACA,gBAAA;EAEA,YAAA;EAEA,qBAAA;EACA,eAAA;AAHJ","sourcesContent":[".navbarmain {\n  position: -webkit-sticky;\n  position: sticky;\n  top: 0;\n  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);\n  background-color: white;\n  z-index: 1000;\n  padding: 2px 10px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-right: auto;\n  margin-left: auto;\n  .logo {\n    width: 100%;\n    max-height: 4.4rem;\n    object-fit: cover;\n    object-position: center;\n  }\n  .link {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n  }\n\n  .linknav_box {\n    display: flex;\n    flex-direction: column;\n  }\n\n  .linknav {\n    text-decoration: none;\n    align-self: center;\n    font-size: 1rem;\n    // background-color: blue;\n    padding: 5px 10px;\n    margin: 3px auto;\n    max-height: 40px;\n    // border-radius: 5px;\n    color: white;\n    // background: #345995;\n    letter-spacing: 0.7px;\n    min-width: 9rem;\n    \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbarmain": `Navbar_navbarmain__kHLkK`,
	"logo": `Navbar_logo__YJM-Q`,
	"link": `Navbar_link__Zoaxm`,
	"linknav_box": `Navbar_linknav_box__mSshM`,
	"linknav": `Navbar_linknav__NPr4n`
};
export default ___CSS_LOADER_EXPORT___;
