import { useEffect, useState } from "react";
import uploadStyles from "./uploadPicture.module.scss";
import { IPicture } from "../../interfaces";

type uploadPictureProps = {
  setPictureInForm: (pictureInfo: any) => void;
  picture?: IPicture
};

const UploadPicture = ({ setPictureInForm, picture }: uploadPictureProps) => {

  const [previewSource, setPreviewSource] = useState("");
  const [fileInputState, setFileInputState] = useState("");
  const [isSending, setIsSending] = useState(false);
  
  useEffect(() => {
    if (picture) {
      setPreviewSource(picture.link)
    }
  }, [picture])

  const handleInputChange = (e: any) => {
    const file = e.target.files?.[0];
    setIsSending(true);
    if (file) {
      previewFile(file);
      setPictureInForm(file);
    }
    setIsSending(false);
    e.target.value = ''; // Reset the file input after processing
  };
  
  const previewFile = (file: File) => {
    const reader: any = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (): void => {
      setPreviewSource(reader.result);
    };
  };

  return (
    <div className="flex flex-col">
      {!previewSource ? (
        <div className="px-2 md:px-0 min-w-[100%] md:min-w-[96%] max-w-[100%] mx-auto flex flex-col grid-cols-1 space-y-2">
          <label className="text-sm font-bold text-gray-500 tracking-wide">Attacher une image</label>
          <div className="flex items-center  justify-center w-full">

            <label className="flex flex-col rounded-lg border-4 border-dashed w-full h-60 p-10 group">
              <div className="h-full w-full text-center flex flex-col items-center justify-cente">
                
                <svg xmlns="http://www.w3.org/2000/svg" className="w-10 h-10 text-blue-400 group-hover:text-blue-600"
                  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                </svg>
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  onChange={handleInputChange}
                  disabled={isSending}
                  className="opacity-0 w-full h-full position-absolute top-0 left-0 cursor-pointer"
                />
                <div className="flex flex-auto max-h-48 w-2/5 mx-auto -mt-10">
                  <img className="has-mask h-36 object-center" src="/assets/imagePresentation/upload-picture.png"
                    alt="upload default présentation" />
                </div>
                <p className="pointer-none text-gray-500 ">
                  <span className="text-sm">Cliquez pour uploder</span> des fichiers ici <br />
                  {/* or <a href="" id="" className="text-blue-600 hover:underline">select a file</a> from your computer */}
                </p>
              </div>
              {/* <input type="file" className="hidden"
               /> */}
            </label>
          </div>
        </div>

      ) : (
        <div className="flex items-center justify-center w-full mb-4">
          <label className="flex flex-col rounded-lg border-4 border-dashed w-full h-68 p-10 group">
            <div className="h-full w-full cursor-pointer text-center flex flex-col items-center justify-cente">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-10 h-10 text-blue-400 group-hover:text-blue-600"
                fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round"
                  stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
              </svg>
              <div className="flex flex-auto max-h-80 mx-auto justify-center -mt-10">
                <img src={previewSource} alt="chosen" />
              </div>
              <p className="relative top-4 pointer-none text-gray-500 ">
                <span className="text-sm">Cliquez pour uploder</span> des fichiers ici <br />
                {/* or <a href="" id="" className="text-blue-600 hover:underline">select a file</a> from your computer */}
              </p>
            </div>
            <input type="file" className="hidden" />
          </label>
        </div>
      )
      }

      <div className={uploadStyles.buttonBox}>
        <input 
          // className="hidden"
          placeholder="Choisissez une photo"
          type="file"
          accept=".jpg, .jpeg, .png"
          onChange={handleInputChange}
          value={fileInputState}
          disabled={isSending}
        />
      </div>
    </div >
  );
};

export default UploadPicture;
