// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main404 {
  margin-top: 50px;
  width: 800px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}
.main404 img {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/NotFound/NotFound.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;AACR","sourcesContent":[".main404{\n    margin-top: 50px;\n    width: 800px;\n    margin-right: auto;\n    margin-left: auto;\n    text-align: center;\n\n    img{\n        width: 100%;\n        height: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
