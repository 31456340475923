import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";

function Footer() {
  return (

    <footer>
      {/* <div className={styles.logoImageBox}>
        <Link className={styles.linklogo} to={"/"}>
          <img className={styles.logo} src="/whiteLogo.png" alt="" />
        </Link>
      </div> */}
      <div className={styles.containerLink}>
        <Link className={styles.linknav} to={"/condition"}>
          <p>Conditions Générales</p>
        </Link>
        <Link className={styles.linknav} to={"/mentions"}>
          <p>Mentions légales</p>
        </Link>
        <Link className={styles.linknav} to={"/privacy"}>
          <p>Politique de confidentialité</p>
        </Link>
      </div>
      {/* <p className={styles.copyright}>Copyright © 2023 RealStory, Inc.</p> */}
    </footer>
  );
}

export default Footer;
