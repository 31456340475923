import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { IBlog, IPicture } from '../../../interfaces';

import Truncate from '../../../utils/functions/Truncate';
import { useMutation, useQuery } from '@apollo/client';
import { DELETE_BLOG } from '../../../graphql/mutations';
import { GET_BLOGS } from '../../../graphql/queries';
import styles from './BlogCard.module.scss';

export type CardProps = {
  blog: IBlog;
};


const BlogCard = ({ blog }: CardProps): JSX.Element => {
  const [doDeleteBlogMutation, { loading: deleteLoading, error: deleteError }] = useMutation(DELETE_BLOG, {
    variables: { id: blog.id },
    onCompleted: () => {
      console.log('Blog deleted successfully');
      // Refresh the page to reflect changes
      window.location.reload();
    },
    onError: (error) => {
      console.error('Error deleting blog:', error.message);
    }
  });

  const handleDeleteBlog = async () => {
    if (window.confirm('Are you sure you want to delete this blog?')) {
      await doDeleteBlogMutation();
      window.location.reload();
    }
  };


  return (
    <div className={styles.card}>
      <Link to={`/blog/${blog.id}`}>
        <img className={styles.blogCardImg} src={blog.picture ? blog.picture.link : 'https://picsum.photos/1200/400?random=2'}
          alt={blog.name}
        />
      </Link>
      <div>
        <div className={styles.boxCardContent}>
          <h3 className={styles.titleCard}>{blog.name}</h3>
          <p>
            <Truncate
              paragraphs={blog.description}
              sliceNumber={30}
            />
          </p>
        </div>
        <div className={styles.footerCard}>
          <p className={styles.dateline}>
            Dernière édition: {moment(blog.updated_at).locale('fr').format('dddd D MMMM YYYY [à] HH[h]mm')}
          </p>
        </div>
        <div className={styles.editeBox}>
          <Link to={`/blog/${blog.id}/modifier`}>
            <button className={`${styles.editButton} bg-blue-800 text-white btn btn-outline-secondary`}>Editer</button>
          </Link>
        </div>
        <div className={styles.deleteBox}>
          <button className={`${styles.deleteButton} bg-slate-200 text-black btn btn-outline-secondary`}
            onClick={handleDeleteBlog}
            disabled={deleteLoading}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
