import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_BLOG } from "../../graphql/mutations";
import styles from "../../styles/forms/forms.module.scss";
import { useNavigate } from "react-router-dom";
import UploadPicture from "../../components/UploadPicture/UploadPicture";
import { uploadCloudinary } from "../../components/UploadPicture/uploadCloudinary";
import { GET_BLOGS } from "../../graphql/queries";

type PictureInfo = {
  original_filename: string;
  secure_url: string;
};


function CreateBlog() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [picture, setPicture] = useState<PictureInfo | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false); // State to prevent multiple submissions

  const navigate = useNavigate();

  const [doCreateBlogMutation, { loading, error }] = useMutation(CREATE_BLOG, {
    variables: {
      data: {
        name,
        description,
        picture: {
          name: picture?.original_filename,
          link: picture?.secure_url,
        },
      },
    },
    refetchQueries: [{ query: GET_BLOGS }],
    onCompleted: (res) => {
      setIsSubmitting(false); // Reset submission state
      if (res && res.createBlog) {
        const id = res.createBlog.id;
        navigate(`/blog/${id}`);
      } else {
        console.error('Mutation response did not contain expected data');
      }
    },
    onError: (err) => {
      setIsSubmitting(false); // Reset submission state
      console.error('Error performing mutation:', err);
    }
  });

  async function doCreateBlog(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (isSubmitting) return; // Prevent further action if already submitting

    setIsSubmitting(true); // Set submitting state to true to prevent re-submissions
    const cloudinaryPicture = await uploadCloudinary(picture);

    await doCreateBlogMutation({
      variables: {
        data: {
          name,
          description,
          picture: {
            name: cloudinaryPicture?.original_filename,
            link: cloudinaryPicture?.secure_url,
          },
        },
      }
    });
  }


  return (
    <main className={styles.main}>
      <div className="w-full min-h-screen flex items-center justify-center bg-gray-50 py-12 xs:px-1 px-2 md:px-4 sm:px-6 lg:px-8 bg-no-repeat bg-cover relative"
        style={{ backgroundImage: "url('/assets/imagePresentation/story-background-xxl.jpg')" }}>
        <div className="absolute bg-black opacity-60 inset-0 z-0"></div>
        <div className="xs:max-w-[98%] md:max-w-[40rem] xs:min-w-[21rem] s:min-w-[35rem]:  w-full md:min-w-[38rem] xs:p-1 md:p-10 max-w-[40rem] bg-white rounded-xl z-10">
          <div className=" text-center">
            <h2 className="mt-5 text-3xl font-bold text-gray-900">
              Créez votre blog
            </h2>
            <p className="mt-2 text-sm md:text-md lg:text-lg text-gray-400">Le commencement vers une nouvelle histoire</p>
          </div>

          <form className="mt-8 space-y-3" onSubmit={(e) => doCreateBlog(e)}>
            
            <div className="px-2 xs:p-2 md:p-3 grid grid-cols-1 space-y-2 ">
              <label className="text-sm font-bold text-gray-500 tracking-wide">Nom du blog</label>
              <input
                placeholder="Le blog des exemples..."
                className="flex item-start text-base p-2 border border-gray-300 rounded-lg 
                focus:outline-none focus:border-indigo-500" type=""

                disabled={loading}
                // type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              // placeholder="Nom du blog"
              />

              <label className="text-sm font-bold text-gray-500 tracking-wide">Description</label>
              <input
                placeholder="Le blog d'un homme raffiné ayant la plume de Molière"
                className="text-base pt-4 pb-32 px-4 border border-gray-300 rounded-lg focus:outline-none 
                focus:border-indigo-500" type="text"
                disabled={loading}
                // type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              // placeholder="Nom du blog"
              />

            </div>

            <UploadPicture setPictureInForm={setPicture} /> 

            <p className="ml-2 text-sm text-gray-300">
              <span className="ml-[5px] text-black md:text-lg">Fichiers type: jpg,jpeg, png</span>
            </p>
            <div>

              <button disabled={loading} type="submit"
                className="my-5 w-full flex justify-center bg-blue-500 text-gray-100 p-4  rounded-full tracking-wide
                font-semibold  focus:outline-none focus:shadow-outline hover:bg-blue-600 shadow-lg cursor-pointer transition ease-in duration-300">
                Créer le blog
              </button>
            </div>
          </form>
        </div>
      </div>





      {/*       
      <form onSubmit={(e) => doCreateBlog(e)} className={styles.form}>
        <h3>Créer mon blog</h3>
        <UploadPicture setPictureInForm={setPicture} />
        <br />
        <input
          disabled={loading}
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Nom du blog"
        />
        <textarea
          disabled={loading}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Description"
        />
        {error && <p style={{ color: "red" }}>Quelque chose s'est mal passé</p>}
        <div className={styles.buttonBox}>
          <button disabled={loading} type="submit">
            Créer
          </button>
        </div>
      </form> */}


    </main>
  );
}

export default CreateBlog;
