// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GoBack_goback__dEmUM {
  cursor: pointer;
  margin-left: 5%;
  position: absolute;
  height: 3rem;
  margin: auto 0 auto 1rem;
}
.GoBack_goback__dEmUM .GoBack_arrowimage__CHFy8 {
  width: 40px;
  height: 2rem;
  top: 0.5rem;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/components/GoBack/GoBack.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,eAAA;EACA,kBAAA;EACA,YAAA;EACA,wBAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;AAEJ","sourcesContent":[".goback {\n  cursor: pointer;\n  margin-left: 5%;\n  position: absolute;\n  height: 3rem;\n  margin: auto 0 auto 1rem;\n  .arrowimage {\n    width: 40px;\n    height: 2rem;\n    top: 0.5rem;\n    position: relative;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"goback": `GoBack_goback__dEmUM`,
	"arrowimage": `GoBack_arrowimage__CHFy8`
};
export default ___CSS_LOADER_EXPORT___;
