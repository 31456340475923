let API_URL: string;
if (window.location.href.includes("real-story")) {
  API_URL = "https://api.real-story.fr";
} 
else if (window.location.href.includes("story-time.cybernetic-systems")) {
  API_URL = "https://api-story-time.cybernetic-systems.fr";
} else if (window.location.href.includes("cybernetic-systems")) {
  API_URL = "https://story-time.cybernetic-systems.fr";
} else {
  API_URL = "http://localhost:5000";
}
 
export default API_URL;