import React from "react";
import styles from "./Card.module.scss";
import { getBlog } from "../../interfaces";
import { Link } from "react-router-dom";
import moment from "moment";
import Truncate from "../../utils/functions/Truncate";

export type CardProps = {
  blog: Partial<getBlog>;
};

function BlogCard({ blog }: CardProps): JSX.Element {
  return (
    <Link to={`/blog/${blog.id}`}
      className="min-w-80 w-80"
    >
      <div className="bg-white shadow-md border border-gray-200 rounded-lg mb-5">
        {blog.picture ? (
          <img className="object-cover min-w-80 h-56 aspect-1/4 rounded-t-lg max-h-80" src={blog.picture.link} alt={blog.picture.name} />
        ) : (
          <img className="object-cover min-w-80 h-56 aspect-1/4 rounded-t-lg max-h-80" src="/default-card-img.png" alt="Introuvable" />
        )}
        <div className="px-2 py-2 h-48">
          <h3 className="text-gray-900 font-bold text-xl tracking-tight mb-2">{blog.name}</h3>
          <p className="font-normal text-gray-700 mb-3">
            {blog.description ? (
              <Truncate paragraphs={blog.description} sliceNumber={24} />
            ) : (
              <p className="font-normal text-gray-700 mb-3">No description available.</p>
            )}
          </p>
        </div>
        <p className="cursor-auto ml-2 mb-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4
             focus:ring-blue-300 font-medium rounded text-sm px-3 py-2 text-center 
             inline-flex items-center">
          {moment(blog.updated_at)
            .locale("fr")
            .format("D MMMM YYYY")}
        </p>
      </div>
    </Link >

  );
}

export default BlogCard;
