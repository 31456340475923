import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import styles from "./../../components/FormSign/formSign.module.scss";
import { LOGIN } from "../../graphql/mutations";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function Login(props: { onTokenChange: (token?: string) => void }) {
  // Redirects to dashboard if there's a user logged in
  const navigate = useNavigate();

  const [email, setEmail] = useState("test@mail.com");
  const [password, setPassword] = useState("test1234");
  const [error, setError] = useState('')

  const [doSignInMutation, { loading }] = useMutation(LOGIN);

  async function doSignIn(e: any) {
    e.preventDefault();
    await doSignInMutation({
      variables: {
        data: {
          email,
          password,
        },
      },
    }).then(res => {
      if (res.data.login) {
        props.onTokenChange(res.data.login);
        navigate("/");
      } else {
        setError('Veuillez vérifier votre adresse mail et votre mot de passe')
      }
    })
  }

  return (
    <main className="">

      {/* 
      <form onSubmit={(e) => doSignIn(e)} className={styles.form}>
        <h3>Connexion</h3>
        <div className={styles.email}>
          <input
            disabled={loading}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Votre email"
          />

          <input
            className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
            type="email" placeholder="Email" />
        </div>
        <div className={styles.password}>
          <input
            disabled={loading}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Votre mot de passe"
          />
        </div>
        {error && <p className="text-danger text-center">{error}</p>}
        <div className={styles.buttonBox}>
          <button disabled={loading} onClick={doSignIn}>
            Me connecter
          </button>
        </div>
        <div>
          <p className={styles.forbidenMdpLink}>
            Avez-vous déja un <a href="X">compte?</a>
          </p>
        </div>
      </form> */}

      <div  className="my-4 mx-auto">
        {/* <h3>Connexion</h3> */}
        <div className="flex full-size-h items-center justify-center ">
          <div className="relative min-h-[60vh] h-[580px] w-[40vw] min-w-[28rem] max-w-[36rem] overflow-hidden rounded-3xl bg-white bg-opacity-20 shadow">
            <div className="bg-cover bg-no-repeat h-full w-full bg-[60%] "
              style={{ backgroundImage: "url('https://images.unsplash.com/photo-1593193583588-66f1dcc0625b?q=80&w=2835&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')" }}>
            </div>
            <div className="absolute bottom-0 flex h-[80%] w-full flex-col rounded-t-3xl">
            <h3  className="mx-auto text-white">Connexion</h3>
              <form onSubmit={(e) => doSignIn(e)} className="mt-10 space-y-8 px-10 py-10 text-center">
                <div className="group relative">
                  <input type="Email" id="Email" required className="peer h-14 w-full rounded-3xl bg-gray-100 px-4 text-sm outline-none"
                    disabled={loading}
                    // type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Votre email"
                  />
                  <label
                    className="text-lg font-bold absolute left-2 top-0 flex h-full transform items-center pl-2
                     transition-all duration-300 group-focus-within:-top-7 group-focus-within:h-1/2 
                     group-focus-within:pl-0 group-focus-within:text-base group-focus-within:text-white
                     peer-valid:-top-7 peer-valid:h-1/2 peer-valid:pl-0 peer-valid:text-base peer-valid:text-white">
                    Username
                  </label>
                </div>

                <div className="group relative">
                  <input type="password" id="password"
                    disabled={loading}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required className="peer h-14 w-full rounded-3xl bg-gray-100 px-4 text-sm outline-none" />
                  <label
                    className="text-lg font-bold absolute left-2 top-0 flex h-full transform 
                    items-center pl-2 transition-all duration-300 
                    group-focus-within:-top-7 group-focus-within:h-1/2 
                    group-focus-within:pl-0 group-focus-within:text-base 
                  group-focus-within:text-white peer-valid:-top-7 peer-valid:h-1/2 
                  peer-valid:pl-0 peer-valid:text-base peer-valid:text-white">
                    Password
                  </label>
                </div>
                {error && <p className="text-danger text-center">{error}</p>}
                <button disabled={loading} onClick={doSignIn}
                  className="h-12 w-full rounded-3xl bg-blue-900 text-white transition-all duration-300 hover:bg-blue-800">Login</button>

                {/* <a href="#" className="inline-flex !w-auto justify-center font-medium text-white">Forgot password?</a> */}
              </form>

              <p className="gap-2 text-center text-white">
                Pas de compte ?
                <Link to="/signup" className="ml-2 font-semibold text-blue-900 hover:text-blue-800">S'inscrire
              </Link>
              </p>

              {/* <a href="#" className="border-white-500 group m-auto mb-4 mt-5 inline-flex h-12 w-[320px] items-center justify-center space-x-2 rounded-3xl border px-4 py-2 transition-colors duration-300 hover:border-blue-500 hover:bg-blue-500 focus:outline-none">
                <span>
                  <svg className="text-white" width="20" height="20" fill="currentColor">
                    <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84"></path>
                  </svg>
                </span>
                <span className="text-sm font-medium text-white">Twitter</span>
              </a> */}
{/* 
              <a href="#" className="border-white-500 group m-auto my-0 inline-flex h-12 w-[320px] items-center justify-center space-x-2 rounded-3xl border px-4 py-2 transition-colors duration-300 hover:border-black hover:bg-black focus:outline-none">
                <span>
                  <svg className="h-5 w-5 fill-current text-white" viewBox="0 0 16 16" version="1.1" aria-hidden="true">
                    <path fill-rule="text-white" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"></path>
                  </svg>
                </span>
                <span className="text-sm font-medium text-white">Github</span>
              </a> */}


            </div>
          </div>
        </div>

      </div>



      
    </main>
  );
}

export default Login;












// <div className="min-h-screen bg-gray-100 text-gray-900 flex justify-center">
//   <div className="max-w-screen-xl m-0 sm:m-10 bg-white shadow sm:rounded-lg flex justify-center flex-1">
//     <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-12">
//       <div>
//         <img src="https://storage.googleapis.com/devitary-image-host.appspot.com/15846435184459982716-LogoMakr_7POjrN.png"
//           className="w-32 mx-auto" />
//       </div>
//       <div className="mt-12 flex flex-col items-center">
//         <h1 className="text-2xl xl:text-3xl font-extrabold">
//           Sign up
//         </h1>
//         <div className="w-full flex-1 mt-8">
//           <div className="flex flex-col items-center">
//             <button
//               className="w-full max-w-xs font-bold shadow-sm rounded-lg py-3 bg-indigo-100 text-gray-800 flex items-center justify-center transition-all duration-300 ease-in-out focus:outline-none hover:shadow focus:shadow-sm focus:shadow-outline">
//               <div className="bg-white p-2 rounded-full">
//                 <svg className="w-4" viewBox="0 0 533.5 544.3">
//                   <path
//                     d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
//                     fill="#4285f4" />
//                   <path
//                     d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
//                     fill="#34a853" />
//                   <path
//                     d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
//                     fill="#fbbc04" />
//                   <path
//                     d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
//                     fill="#ea4335" />
//                 </svg>
//               </div>
//               <span className="ml-4">
//                 Sign Up with Google
//               </span>
//             </button>

//             <button
//               className="w-full max-w-xs font-bold shadow-sm rounded-lg py-3 bg-indigo-100 text-gray-800 flex items-center justify-center transition-all duration-300 ease-in-out focus:outline-none hover:shadow focus:shadow-sm focus:shadow-outline mt-5">
//               <div className="bg-white p-1 rounded-full">
//                 <svg className="w-6" viewBox="0 0 32 32">
//                   <path fill-rule="evenodd"
//                     d="M16 4C9.371 4 4 9.371 4 16c0 5.3 3.438 9.8 8.207 11.387.602.11.82-.258.82-.578 0-.286-.011-1.04-.015-2.04-3.34.723-4.043-1.609-4.043-1.609-.547-1.387-1.332-1.758-1.332-1.758-1.09-.742.082-.726.082-.726 1.203.086 1.836 1.234 1.836 1.234 1.07 1.836 2.808 1.305 3.492 1 .11-.777.422-1.305.762-1.605-2.664-.301-5.465-1.332-5.465-5.93 0-1.313.469-2.383 1.234-3.223-.121-.3-.535-1.523.117-3.175 0 0 1.008-.32 3.301 1.23A11.487 11.487 0 0116 9.805c1.02.004 2.047.136 3.004.402 2.293-1.55 3.297-1.23 3.297-1.23.656 1.652.246 2.875.12 3.175.77.84 1.231 1.91 1.231 3.223 0 4.61-2.804 5.621-5.476 5.922.43.367.812 1.101.812 2.219 0 1.605-.011 2.898-.011 3.293 0 .32.214.695.824.578C24.566 25.797 28 21.3 28 16c0-6.629-5.371-12-12-12z" />
//                 </svg>
//               </div>
//               <span className="ml-4">
//                 Sign Up with GitHub
//               </span>
//             </button>
//           </div>

//           <div className="my-12 border-b text-center">
//             <div
//               className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2">
//               Or sign up with e-mail
//             </div>
//           </div>

//           <div className="mx-auto max-w-xs">
//             <input
//               className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
//               type="email" placeholder="Email" />
//             <input
//               className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
//               type="password" placeholder="Password" />
//             <button
//               className="mt-5 tracking-wide font-semibold bg-indigo-500 text-gray-100 w-full py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none">
//               <svg className="w-6 h-6 -ml-2" fill="none" stroke="currentColor" stroke-width="2"
//                 stroke-linecap="round" stroke-linejoin="round">
//                 <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
//                 <circle cx="8.5" cy="7" r="4" />
//                 <path d="M20 8v6M23 11h-6" />
//               </svg>
//               <span className="ml-3">
//                 Sign Up
//               </span>
//             </button>
//             <p className="mt-6 text-xs text-gray-600 text-center">
//               I agree to abide by templatana's
//               <a href="#" className="border-b border-gray-500 border-dotted">
//                 Terms of Service
//               </a>
//               and its
//               <a href="#" className="border-b border-gray-500 border-dotted">
//                 Privacy Policy
//               </a>
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//     <div className="flex-1 bg-indigo-100 text-center hidden lg:flex">
//       <div
//         className="m-12 xl:m-16 w-full bg-contain bg-center bg-no-repeat"
//         style={{ backgroundImage: "url('https://storage.googleapis.com/devitary-image-host.appspot.com/15848031292911696601-undraw_designer_life_w96d.svg')" }}>
//       </div>
//     </div>

//   </div>
// </div>