import React from "react";
import styles from "./Home.module.scss";

function Privacy() {
  return (
    <main>

      {/* <ul className="list-disc list-inside mb-4">
        <li>Internal record keeping</li>
        <li>Improving our products and services</li>
        <li>Sending promotional emails about new products, special offers, or other information which we think you
          may
          find
          interesting</li>
        <li>From time to time, we may also use your information to contact you for market research purposes. We may
          contact
          you by email, phone, or mail. We may use the information to customize the website according to your
          interests.</li>
      </ul> */}


      <div className="bg-gray-100">
        <div className="container mx-auto px-4 py-8">
          <h1 className="mb-16">
            <strong>Politique de confidentialité</strong>
          </h1>
          <h2 className="text-2xl font-bold mb-2">Politique Story-life</h2>
          <p className="mb-4">
            <strong>Story life</strong>, en sa qualité de responsable du traitement
            de vos données, attache une grande importance à la protection et au
            respect de votre vie privée. En effet, nous estimons que vous devriez
            toujours savoir quelles sont les données que nous recueillons de votre
            part et comment nous les utilisons. La présente politique vise à vous
            informer de nos pratiques concernant la collecte, l&#x27;utilisation
            et le droit d&#x27;accès à ces données que vous êtes amenés à nous
            fournir par le biais de nos services.
          </p>
          <p className="mb-4">
            Cette politique, ainsi que nos Conditions générales
            d&#x27;utilisation, présente la manière dont nous traitons les données
            personnelles que vous nous fournissez ou que nous recueillons. Les
            données de <strong>RealStory</strong> sont stockées sur des serveurs en
            dans des satellites français et relèvent du droit français et a
            fortiori du droit européen. A cet égard les fichiers
            <strong>RealStory</strong> feront l&#x27;objet d&#x27;une déclaration
            auprès de la CNIL et sont en conformité avec les dispositions du
            Règlement Général pour la protection des données (RGPD).
          </p>
          <p className="mb-4">
            Nous vous invitons à lire attentivement le présent document pour
            connaître et comprendre nos pratiques quant aux traitements de vos
            données personnelles que nous mettons en œuvre.
          </p>
          <h2 className="text-2xl font-bold mb-2">
            Les informations que nous recueillons
          </h2>
          <p className="mb-4">
            Nous sommes susceptibles de recueillir et de traiter les données que
            vous nous transmettez directement.
          </p>
          <p className="mb-4">
            En utilisant nos Services, vous êtes amenés à nous transmettre des
            informations, dont certaines sont de nature à vous identifier, telles
            que des images, votre pseudonyme, votre adresse mail... C&#x27;est
            notamment le cas lorsque vous remplissez des formulaires (comme par
            exemple le formulaire d&#x27;inscription), ou lorsque vous nous
            contactez - que ce soit par formulaire, email ou tout autre moyen de
            communication.
          </p>
          <p className="mb-4">
            Ces données sont nécessaires à la fourniture du service. Ces
            informations sont obligatoires. A défaut, <strong>RealStory</strong> ne
            sera pas en mesure de vous fournir les services attendus et vous ne
            serez pas autorisés à créer de compte sur notre Service.
          </p>
          <h2 className="text-2xl font-bold mb-2">
            Les données que nous recueillons automatiquement
          </h2>
          <p className="mb-4">
            Lors de chacune de vos visites, nous sommes susceptibles de
            recueillir, conformément à la législation applicable, des informations
            relatives aux appareils sur lesquels vous utilisez nos services ou aux
            réseaux depuis lesquels vous accédez à nos services, tels que
            notamment vos adresses IP, vos données de connexion, les données
            concernant votre parcours de navigation sur notre service, le contenu
            auquel vous accédez ou que vous consultez, les termes de recherches
            utilisés, les erreurs de téléchargement, la durée de votre
            consultation, l&#x27;identifiant publicitaire de votre appareil...
          </p>
          <h2 className="text-2xl font-bold mb-2"
          >Les destinataires de vos données
          </h2>
          <p className="mb-4">
            Les données collectées sur nos Services et applications sont
            exclusivement destinées à <strong>RealStory</strong>.
          </p>
          <h2 className="text-2xl font-bold mb-2">
            Durée de conservation de vos données
          </h2>
          <p className="mb-4">
            Vos données personnelles sont effacées lorsque vous supprimez votre
            compte
          </p>
          <h2 className="text-2xl font-bold mb-2">
            Vos droits sur vos données personnelles
          </h2>
          <p className="mb-4">
            Vous disposez du droit de recevoir une copie de vos données
            personnelles en notre possession (« droit d&#x27;accès »). Vous pouvez
            également demander l&#x27;effacement de vos données personnelles ainsi
            que la rectification des données personnelles erronées ou obsolètes («
            droit d&#x27;effacement et droit de rectification »). Veuillez noter
            que nous sommes susceptibles de conserver certaines informations vous
            concernant lorsque la loi nous l&#x27;impose ou lorsque nous avons un
            motif légitime de le faire. C&#x27;est par exemple le cas, si nous
            estimons que vous avez commis une fraude ou violé nos Conditions
            Générales d&#x27;Utilisation et que nous souhaitons éviter que vous
            contourniez les règles applicables à notre communauté.
          </p>
          <p className="mb-4">
            Vous disposez également du droit de vous opposer à tout moment pour
            des raisons tenant à sa situation particulière, au traitement de vos
            Données Personnes à des fins de marketing direct ou aux traitements
            effectués sur le fondement de notre intérêt légitime (« droit
            d&#x27;opposition »). Vous disposez également du droit à la
            portabilité de vos données, c&#x27;est-à-dire au droit de recevoir les
            Données Personnelles que vous nous avez fournis dans un format
            structuré, couramment utilisé et lisible par la machine et le droit de
            transmettre ces données à un autre responsable du traitement (« droit
            à la portabilité »).
          </p>
          <p className="mb-4">
            Pour exercer ces droits, vous pouvez contacter notre Délégué à la
            Protection des Données Personnelles à l&#x27;adresse email suivante :
            dpo@yopmail.com ou en nous adressant un courrier à l&#x27;adresse
            suivante : <strong>RealStory</strong> - A l&#x27;attention du Délégué à
            la Protection des Données - 404 rue des Etoiles - 54321 Starville -
            France
          </p>
          <br />
          <p className="mb-4">
            <i>Version mise à jour le 30 janvier 2023.</i>
          </p>
        </div>
      </div>
    </main>
  );
}

export default Privacy;
