import React, { useContext } from "react";
import styles from "./Card.module.scss";
import { DELETE_COMMENT } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { GET_POST } from "../../graphql/queries";
import { UserContext } from "../../UserContext";
import moment from "moment";

function CommentCard({ comment, post }: any): JSX.Element {
  const [deleteComment] = useMutation(DELETE_COMMENT, {
    refetchQueries: [GET_POST],
  });
  const user = useContext(UserContext);

  const handleDelete = async () => {
    const yes = window.confirm(
      "Êtes-vous sûr(e) de vouloir supprimer ce commentaire ?"
    );
    if (yes) {
      try {
        await deleteComment({
          variables: {
            deleteCommentId: comment.id,
          },
        });
      } catch (error) {
        console.error(
          "Une erreur s'est produite lors de la suppression du commentaire :",
          error
        );
      }
    } else {
    }
  };
  return (
    <>


      <div className="mt-4 flex-1 border rounded-lg px-4 py-2 mb-4 sm:px-6 sm:py-4 leading-relaxed">
        <strong>{comment?.user?.pseudo}</strong> <span className="text-xs text-gray-400">
          {moment(comment?.created_at)
            .locale("fr")
            .format("dddd D MMMM YYYY [à] HH[h]mm")}</span>
        <p className="text-sm">
          {comment?.text}
        </p>
        <div className="mt-4 flex items-center">
          <div className="flex -space-x-2 mr-2">

          </div>
          {/* <div className="text-sm text-gray-500 font-semibold">
            5 Replies
          </div> */}
        </div>
        {user?.id === comment.user.id || user?.id === post.blog.user.id ? (
          <button className="btn bg-red-900 rounded-sm p-1 text-white" onClick={handleDelete}>
            Supprimer
          </button>
        ) : (
          ""
        )}

      </div>

    </>
  );
}

export default CommentCard;
