import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { CREATE_POST } from "../../graphql/mutations";
import UploadPicture from "../../components/UploadPicture/UploadPicture";
import { uploadCloudinary } from "../../components/UploadPicture/uploadCloudinary";
import styles from "../Posts/createPost.module.scss";

const CreatePost = () => {
  const { blogId } = useParams();
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [picture, setPicture] = useState("");
  const [isArchived, setIsArchived] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // State to prevent multiple submissions

  const navigate = useNavigate();
  const [doCreatePostMutation, { loading, error }] = useMutation(CREATE_POST, {
    onCompleted: (res) => {
      navigate(`/blog/${blogId}`); // Redirect after creation
      setIsSubmitting(false); // Reset submission state
    },
    onError: (err) => {
      console.error('Error creating post:', err);
      setIsSubmitting(false); // Reset submission state
    }
  });

  async function doCreatePost(e: any) {
    e.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true); // Prevent further submissions
    const cloudinaryPicture = await uploadCloudinary(picture);

    doCreatePostMutation({
      variables: {
        blogId: blogId,
        data: {
          title,
          summary,
          content,
          picture: {
            name: cloudinaryPicture?.original_filename,
            link: cloudinaryPicture?.secure_url,
          },
          isArchived,
        },
      }
    });
  }

  function handleArchiveClick(e: any) {
    e.preventDefault();
    setIsArchived(true);
    doCreatePost(e); // Proceed to create the post with the archive flag set
  }

  return (
    <main className={styles.main}>
      <form onSubmit={doCreatePost} className={styles.form}>
        <h3>Rédiger un article</h3>
        <UploadPicture setPictureInForm={setPicture} />
        <br className="mb-4" />
        <input
          disabled={loading || isSubmitting}
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Titre de l'article"
          className="flex item-start text-base p-2 border border-gray-800 rounded-lg  focus:outline-none focus:border-indigo-500"
        />
        <textarea
          disabled={loading || isSubmitting}
          value={summary}
          onChange={(e) => setSummary(e.target.value)}
          placeholder="Résumé"
          className="flex item-start text-base p-2 border border-gray-800 rounded-lg  focus:outline-none focus:border-indigo-500"

        />
        <textarea
          disabled={loading || isSubmitting}
          value={content}
          onChange={(e) => setContent(e.target.value)}
          style={{ height: '20rem' }}
          placeholder="Contenu"
          className="flex item-start text-base p-2 border border-gray-800 rounded-lg  focus:outline-none focus:border-indigo-500"
        />
        {error && <p style={{ color: "red" }}>Quelque chose s'est mal passé: {error.message}</p>}
        <div className="flex flex-row mx-auto justify-content-center w-[95%] gap-3">
          <button type="submit" disabled={loading || isSubmitting}
            className="btn btn-blue px-4 py-2  rounded-md bg-blue-800 text-white border border-1 w-32 font-medium tracking-wider"
            style={{ height: 'fit-content' }}>
            Créer
          </button>
          <button type="button" // Set type to "button" to prevent form submission
            className="btn btn-blue px-4 py-2 rounded-md bg-slate-200 text-blackext-base p-2 border border-gray-800  focus:outline-none focus:border-indigo-500 w-32 font-medium tracking-wider"
            onClick={handleArchiveClick} disabled={loading || isSubmitting}>
            Archiver
          </button>
        </div>
      </form>
    </main>
  );
};

export default CreatePost;




      {/* <div className="flex flex-col">
      {!previewSource ? (
        <div className="grid grid-cols-1  space-y-2">
          <label className="text-sm font-bold text-gray-500 tracking-wide">Attacher une image</label>
          <div className="flex items-center  justify-center w-full">

            <label className="flex flex-col rounded-lg border-4 border-dashed w-full h-60 p-10 group">
              <div className="h-full w-full text-center flex flex-col items-center justify-cente">
                
                <svg xmlns="http://www.w3.org/2000/svg" className="w-10 h-10 text-blue-400 group-hover:text-blue-600"
                  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                </svg>
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  onChange={handleInputChange}
                  disabled={isSending}
                  className="opacity-0 w-full h-full position-absolute top-0 left-0 cursor-pointer"
                />
                <div className="flex flex-auto max-h-48 w-2/5 mx-auto -mt-10">
                  <img className="has-mask h-36 object-center" src="/assets/imagePresentation/upload-picture.png"
                    alt="upload default présentation" />
                </div>
                <p className="pointer-none text-gray-500 ">
                  <span className="text-sm">Cliquez pour uploder</span> des fichiers ici <br />
                </p>
              </div>

            </label>
          </div>
        </div>

      ) : (
        <div className="flex items-center justify-center w-full mb-4">
          <label className="flex flex-col rounded-lg border-4 border-dashed w-full h-68 p-10 group">
            <div className="h-full w-full cursor-pointer text-center flex flex-col items-center justify-cente">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-10 h-10 text-blue-400 group-hover:text-blue-600"
                fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round"
                  stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
              </svg>
              <div className="flex flex-auto max-h-80 mx-auto justify-center -mt-10">
                <img src={previewSource} alt="chosen" />
              </div>
              <p className="relative top-4 pointer-none text-gray-500 ">
                <span className="text-sm">Cliquez pour uploder</span> des fichiers ici <br />
              </p>
            </div>
            <input type="file" className="hidden" />
          </label>
        </div> */}


