// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --textcolor: #000001;
  --textcolorwhite: #fef6c9;
  --textcolorgrey: #4f4346;
  --primarycolor: #345995;
  --secondarycolor: #8eb8e5;
}

a {
  text-decoration: none !important;
  /* no underline */
}

body {
  font-family: "IBM Plex Sans", sans-serif;
  color: var(--textcolor);
  margin: 0;
  text-decoration: none;
}

main {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

h1,
h2,
h3 {
  margin: 0 0 0.35rem;
  font-family: "Nanum Myeongjo", serif;
}

h1 {
  font-size: 3rem;
  text-align: center;
}

h2 {
  font-size: 1.6rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1rem;
}

p {
  margin: 0;
  font-size: 0.92rem;
}

.dateline {
  font-style: italic;
  font-size: 0.8rem;
}
.dateline::first-letter {
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EAEE,oBAAA;EACA,yBAAA;EACA,wBAAA;EACA,uBAAA;EACA,yBAAA;AACF;;AAGA;EACE,gCAAA;EACA,iBAAA;AAAF;;AAGA;EACE,wCAAA;EACA,uBAAA;EACA,SAAA;EACA,qBAAA;AAAF;;AAGA;EACE,iBAAA;EACA,kBAAA;EACA,WAAA;AAAF;;AAGA;;;EAGE,mBAAA;EACA,oCAAA;AAAF;;AAGA;EACE,eAAA;EACA,kBAAA;AAAF;;AAGA;EACE,iBAAA;AAAF;;AAGA;EACE,iBAAA;AAAF;;AAGA;EACE,eAAA;AAAF;;AAGA;EACE,SAAA;EACA,kBAAA;AAAF;;AAIA;EACE,kBAAA;EACA,iBAAA;AADF;AAGE;EACE,yBAAA;AADJ","sourcesContent":[":root {\n  --secondarycolor: #8eb8e5;\n  --textcolor: #000001;\n  --textcolorwhite: #fef6c9;\n  --textcolorgrey: #4f4346;\n  --primarycolor: #345995;\n  --secondarycolor: #8eb8e5;\n}\n\n\na {\n  text-decoration: none !important;\n  /* no underline */\n}\n\nbody {\n  font-family: \"IBM Plex Sans\", sans-serif;\n  color: var(--textcolor);\n  margin: 0;\n  text-decoration: none;\n}\n\nmain {\n  margin-left: auto;\n  margin-right: auto;\n  width: 100%;\n}\n\nh1,\nh2,\nh3 {\n  margin: 0 0 0.35rem;\n  font-family: \"Nanum Myeongjo\", serif;\n}\n\nh1 {\n  font-size: 3rem;\n  text-align: center;\n}\n\nh2 {\n  font-size: 1.6rem;\n}\n\nh3 {\n  font-size: 1.5rem;\n}\n\nh4 {\n  font-size: 1rem;\n}\n\np {\n  margin: 0;\n  font-size: 0.92rem;\n}\n\n\n.dateline {\n  font-style: italic;\n  font-size: 0.8rem;\n\n  &::first-letter {\n    text-transform: uppercase;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
