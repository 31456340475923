// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Premium_premium__WGuFA {
  text-align: center;
  padding: 1rem;
  min-height: calc(100vh - 10.8rem);
}`, "",{"version":3,"sources":["webpack://./src/pages/Premium/Premium.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,iCAAA;AACF","sourcesContent":[".premium {\r\n  text-align: center;\r\n  padding: 1rem;\r\n  min-height: calc(100vh - 10.8rem);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"premium": `Premium_premium__WGuFA`
};
export default ___CSS_LOADER_EXPORT___;
