import React, { useContext } from "react";
import { UserContext } from "../../UserContext";
import styles from "./Home.module.scss";
// import Carousel from "react-bootstrap/Carousel";
// import "bootstrap/dist/css/bootstrap.min.css";
import { GET_BLOGS } from "../../graphql/queries";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import BlogCard from "../../components/Card/BlogCard";

export type PictureProps = {
  id: number;
  name: string;
  link: string;
  updated_at: string;
};


export type BlogProps = {
  user: any;
  name: string;
  description: string;
  updated_at: string;
  id: number;
  picture?: PictureProps;
};

function TruncateTitle(props: any) {
  const words = props.title.split(/\s+/);
  let truncatedTitle = words.slice(0, 5).join(' ');

  if (words.length > 8) {
    truncatedTitle += '...';
  }

  return <h3>{truncatedTitle}</h3>;
}

function Home() {
  // Getting current user from context
  const user = useContext(UserContext);
  const { loading, data } = useQuery<{ getBlogs: BlogProps[] }>(GET_BLOGS);

  let blogsSorted: BlogProps[] = [];

  let lastBlogs: BlogProps[] = [];

  if (data?.getBlogs) {
    blogsSorted = [...data.getBlogs].sort((a, b) => {
      return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
    });

    const usersBlogs: { [key: string]: BlogProps } = {};
    let getBlogReversed = Object.keys(data.getBlogs).reverse();

    getBlogReversed.forEach(key => {
      let index = parseInt(key);
      console.log(index, data.getBlogs[index]);
    });

    for (const blog of data.getBlogs) {
      lastBlogs.push(blog);
      if (lastBlogs.length === 3) {
        break;
      }
    }
  }
  

  return (
    <main className={styles.homeMain}>
      <section>
        {/* <div className={styles.banniere_box_img}>
          <img src="./imgbanniere.png" alt="" />
        </div>

        <div className={styles.contentBanniere}>
          <h1 className="text-left">
            Des histoires<br></br>comme vous les aimez
          </h1>

        </div> */}
        {/* style={{ backgroundImage: "url('https://storage.googleapis.com/devitary-image-host.appspot.com/15848031292911696601-undraw_designer_life_w96d.svg')" }}> */}

        <div className="bg-cover bg-center  h-auto text-white py-24 px-10 object-fill" 
        
        style={{ backgroundImage: "url('/assets/imagePresentation/blog-presentation/home-jumbotron.webp')" }}>
          <div className="md:w-1/2">
            <p className="font-bold text-sm uppercase">Story time</p>
            <p className="text-3xl font-bold">Des vies intéressantes</p>
            <Link to={user ? "/blog/create" : "/login"}>
              {/* <button className="bg-blue-800">Créer un blog</button> */}
            </Link>
            <p  className="text-2xl mb-10 leading-none">La réalité dépasse la fiction</p>
            <Link to={user ? "/blog/create" : "/login"}
             className="bg-blue-800 py-4 px-8 text-white font-bold uppercase text-xs rounded hover:bg-gray-200 hover:text-gray-800">
              Créer mon blog
            </Link>
          </div>
        </div>


      </section>
      <section className={styles.carroussel}>
        {/* <h2 className={styles.titleBlogList}>Les derniers blogs</h2> */}

        {/* <Carousel>
          {!loading && lastBlogs.map((blog, index) => (
            <Carousel.Item key={index}>
              {blog.picture && (
                <img
                  className={`d-block w-100 ${styles.imageStyle}`}
                  src={blog.picture.link}
                  alt={blog.name}
                />
              )}
              <Carousel.Caption>
                <TruncateTitle title={blog.name} />
                <p className={styles.carrousselDescription}>
                  {blog.description.slice(0, 35)}
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel> */}

      </section>
      <h1 className="mt-8 mb-8 text-3xl underline">Les blogs</h1>
      <section className={styles.container}>
        {loading && <div>Chargement...</div>}
        {!loading && blogsSorted.map((blog, index) => (
          <BlogCard key={index} blog={blog} />
        ))}

        <div className={`${styles.articlelist} ${styles.heightZero}`}></div>
        <div className={`${styles.articlelist} ${styles.heightZero}`}></div>
        <div className={`${styles.articlelist} ${styles.heightZero}`}></div>

      </section>
    </main>
  );
}

export default Home;
