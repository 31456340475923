// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uploadPicture_uploaded_preview__vxaSR {
  width: auto;
  margin: auto;
}
.uploadPicture_uploaded_preview__vxaSR img {
  max-height: 15rem;
  max-width: 50%;
  min-width: 20rem;
  display: block;
  object-fit: cover;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.uploadPicture_buttonBox__wPP9C {
  display: flex;
  align-content: center;
  align-items: flex-end;
  position: relative;
  margin-top: 2rem;
  margin-bottom: 1rem;
  left: 12px;
  font-size: 1.4rem;
  height: 2rem;
}
.uploadPicture_buttonBox__wPP9C input::file-selector-button {
  border-radius: 5px;
  border: 0.3px solid black;
  color: rgb(0, 0, 0);
  font-size: 1em;
  background: rgb(232, 232, 232);
}

@media (max-width: 800px) {
  .uploadPicture_uploaded_preview__vxaSR img {
    min-width: 15rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/UploadPicture/uploadPicture.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;AACE;EACE,iBAAA;EACA,cAAA;EACA,gBAAA;EACA,cAAA;EACA,iBAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;;AAGA;EACE,aAAA;EACA,qBAAA;EACA,qBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,UAAA;EACA,iBAAA;EACA,YAAA;AAAF;AAGE;EAEE,kBAAA;EACA,yBAAA;EACA,mBAAA;EAEA,cAAA;EACA,8BAAA;AAHJ;;AASA;EAGI;IACE,gBAAA;EARJ;AACF","sourcesContent":[".uploaded_preview {\n  width: auto;\n  margin: auto;\n\n  img {\n    max-height: 15rem;\n    max-width: 50%;\n    min-width: 20rem;\n    display: block;\n    object-fit: cover;\n    margin-bottom: 1rem;\n    margin-left: auto;\n    margin-right: auto;\n  }\n}\n\n.buttonBox {\n  display: flex;\n  align-content: center;\n  align-items: flex-end;\n  position: relative;\n  margin-top: 2rem;\n  margin-bottom: 1rem;\n  left: 12px;\n  font-size: 1.4rem;\n  height: 2rem;\n\n\n  input::file-selector-button {\n    // padding: 5px 13px;\n    border-radius: 5px;\n    border: 0.3px solid black;\n    color: rgb(0, 0, 0);\n\n    font-size: 1em;\n    background: rgb(232, 232, 232);\n  }\n}\n\n\n\n@media (max-width: 800px) {\n  .uploaded_preview {\n\n    img {\n      min-width: 15rem;\n    }\n\n\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploaded_preview": `uploadPicture_uploaded_preview__vxaSR`,
	"buttonBox": `uploadPicture_buttonBox__wPP9C`
};
export default ___CSS_LOADER_EXPORT___;
